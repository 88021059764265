<template lang="pug">
div
  .col-12
    om-heading.mb-4(h3) {{ $t('needHelp.title') }}
  .d-flex.col-12.pt-2
    img(:src="require('@/assets/admin/svg/pointing-monk.svg')")
    a.d-flex.brand-box-item.align-items-start.ml-lg-5(
      v-for="(item, index) in helpTexts"
      :href="getLink(index)"
      target="_blank"
    )
      .brand-box-separator
      .d-flex.flex-wrap
        om-heading.mb-3(h5) {{ $t(item.title) }}
        om-body-text(bt400sm) {{ $t(item.desc) }}
</template>
<script>
  export default {
    data() {
      return {
        helpTexts: [
          { title: 'needHelp.first.title', desc: 'needHelp.first.lead' },
          { title: 'needHelp.second.title', desc: 'needHelp.second.lead' },
          { title: 'needHelp.third.title', desc: 'needHelp.third.lead' },
        ],
      };
    },
    methods: {
      getLink(index) {
        if (index === 0)
          return 'https://landing.optimonk.hu/digitalis-ugyfelelmeny-optimalizacio-utmutato/';
        if (index === 1)
          return 'https://www.optimonk.hu/hogyan-novelte-meg-az-avon-eves-megrendeleseit-150-kal/';
        if (index === 2) {
          const { userId } = this.$route.params;
          const templatesLink = this.$router.resolve({ name: 'templates', params: { userId } });
          return templatesLink.href;
        }
      },
    },
  };
</script>
