<template lang="pug">
div
  h2 Modules
  figure.highlighted
    .d-flex.mb-3
      need-help
</template>

<script>
  import NeedHelp from '@/components/NeedHelp.vue';

  export default {
    components: { NeedHelp },
  };
</script>
