<template lang="pug">
#need-help.row
  need-help-hu(v-if="lang === 'hu'")
  need-help-en(v-else)
</template>

<script>
  import NeedHelpHu from '@/components/NeedHelpHu.vue';
  import NeedHelpEn from '@/components/NeedHelpEn.vue';

  export default {
    components: { NeedHelpHu, NeedHelpEn },
    computed: {
      lang() {
        return this.$i18n.locale;
      },
    },
  };
</script>
